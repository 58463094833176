import { useMemo } from 'react';
import _ from 'lodash';
import { useGetPatientQuery } from '../api/patientApi';
import { meetsProfileDependency } from '../helpers/logic_helpers';
import {
  eventCompletedDate,
  eventIsActive,
  eventPhaseIsActive,
  eventSchedule,
  patientProgramPhase,
  repeatEventIsActive,
} from '../helpers/appevent_helpers';
import usePatientDates, { PatientDates } from './usePatientDates';
import { AppEvent } from '../types/ApplicationEvent';
import { CompletedEvent, ProfileKeysById } from '../types/Patient';
import { useGetApplicationEventsQuery } from '../api/applicationEventApi';

const eventMeetsProfile = (event: AppEvent, profile: ProfileKeysById) => {
  let meetsProfile = true;

  const profileDependency = event.profileDependencies;

  meetsProfile = meetsProfileDependency(
    profileDependency,
    event.profileDependencyType,
    profile,
  );

  return meetsProfile;
};

const maternityEventsByDeliveryStatus = (
  events: AppEvent[],
  phase: string,
  confirmedSurgery: boolean,
) => {
  if (phase === 'inPeriop' && !confirmedSurgery) {
    // if the patient is in hospital but baby was not delivered yet
    // i.e. do not show post-partum events
    return _.reject(events, ['inPreop', false]);
  }

  if (phase === 'inPeriop' && confirmedSurgery) {
    // if the baby was delivered remove events that start in preop
    // i.e. do not show pregnancy events
    return _.reject(events, ['inPreop', true]);
  }

  return events;
};

// adds startDateTime and endDateTime to event object
// adds completedDate and done boolean to event object
const addScheduleToEvent = (
  events: AppEvent[],
  dates: PatientDates,
  completedEvents: CompletedEvent[],
) => {
  const eventsWithSchedule = events.map((event) => {
    const { startDateTime, notificationTime, endDateTime } = eventSchedule(event, dates);
    let completedDate = null;

    if (startDateTime) {
      completedDate = eventCompletedDate(event, startDateTime, completedEvents);
    }

    const eventCopy = {
      ...event,
      startDateTime,
      notificationTime,
      endDateTime,
      completedDate,
      done: !_.isEmpty(completedDate),
    };
    return eventCopy;
  });

  return eventsWithSchedule;
};

export default function useAppEvents(options = { skip: false }) {
  const { data: patient } = useGetPatientQuery(undefined, { skip: options.skip });
  const { data: applicationEvents, isLoading } = useGetApplicationEventsQuery(undefined, {
    refetchOnMountOrArgChange: 3600,
    skip: options.skip
  });

  const { patientDates } = usePatientDates({ skip: options.skip });

  const completedEvents = useMemo(() => {
    if (!patient) return [];
    return patient.completedEvents;
  }, [patient]);

  const programPhase = useMemo(() => {
    if (!patient || !patientDates) return undefined;

    const { daysPostOp } = patient;
    const phase = patientProgramPhase(patientDates, daysPostOp);
    return phase;
  }, [patient, patientDates]);

  const profile = useMemo(() => {
    if (!patient) return null;
    return patient.profile;
  }, [patient]);

  const clinicalContact = useMemo(() => {
    if (!patient) return null;
    return patient.clinicalContact;
  }, [patient]);

  const isMaternityProgram = useMemo(() => {
    if (!patient) return null;
    const { maternityProgram, confirmedSurgery } = patient;

    if (!maternityProgram) return null;
    return { confirmedSurgery };
  }, [patient]);

  const eventsForProfile = useMemo(() => {
    if (!profile || !applicationEvents?.messageEvents) return null;

    let {
      messageEvents,
      todoEvents,
    } = applicationEvents;

    messageEvents = messageEvents
      .filter((event) => eventMeetsProfile(event, profile));

    todoEvents = todoEvents
      .filter((event) => eventMeetsProfile(event, profile));

    return {
      messageEvents,
      todoEvents,
    };
  }, [applicationEvents, profile]);

  const eventsWithSchedule = useMemo(() => {
    if (!eventsForProfile || !patientDates) return null;

    const {
      messageEvents,
      todoEvents,
    } = eventsForProfile;

    return {
      messageEvents: addScheduleToEvent(messageEvents, patientDates, completedEvents),
      todoEvents: addScheduleToEvent(todoEvents, patientDates, completedEvents),
    };
  }, [completedEvents, eventsForProfile, patientDates]);

  const unread = useMemo(() => {
    if (!eventsWithSchedule || !programPhase) {
      return [];
    }

    const { messageEvents } = eventsWithSchedule;

    const unreadMessages = messageEvents
      .filter((event) => (_.isEmpty(event.completedDate) && eventPhaseIsActive(event, programPhase)))
      .filter((event) => (event.repeating ? repeatEventIsActive(event) : eventIsActive(event)));

    if (!_.isEmpty(isMaternityProgram)) {
      const { confirmedSurgery } = isMaternityProgram;
      return maternityEventsByDeliveryStatus(unreadMessages, programPhase, confirmedSurgery);
    }

    return unreadMessages;
  }, [eventsWithSchedule, programPhase, isMaternityProgram]);

  const activeTodos = useMemo(() => {
    if (!eventsWithSchedule || !programPhase) {
      return [];
    }

    const { todoEvents } = eventsWithSchedule;

    const todoList = todoEvents
      .filter((event) => (eventPhaseIsActive(event, programPhase)))
      .filter((event) => (event.repeating ? repeatEventIsActive(event) : eventIsActive(event)));

    if (!_.isEmpty(isMaternityProgram)) {
      const { confirmedSurgery } = isMaternityProgram;
      return maternityEventsByDeliveryStatus(todoList, programPhase, confirmedSurgery);
    }

    return todoList.sort((a, b) => Number(a.done) - Number(b.done));
  }, [eventsWithSchedule, programPhase, isMaternityProgram]);

  const todoCount = useMemo(() => {
    if (!activeTodos) return 0;
    return activeTodos.filter((tasks) => !tasks.done).length;
  }, [activeTodos]);

  const futureTodos = useMemo(() => {
    if (!eventsWithSchedule || !programPhase) return [];
    const { todoEvents } = eventsWithSchedule;

    const futureList = todoEvents
      .filter((event) => eventPhaseIsActive(event, programPhase))
      .filter((event) => !event.repeating
      && event.startDateTime
      && (_.now() < event.startDateTime));

    if (!_.isEmpty(isMaternityProgram)) {
      const { confirmedSurgery } = isMaternityProgram;
      return maternityEventsByDeliveryStatus(futureList, programPhase, confirmedSurgery);
    }

    return futureList;
  }, [eventsWithSchedule, isMaternityProgram, programPhase]);

  const localNotifications = useMemo(() => {
    if (!eventsWithSchedule || !programPhase) {
      return [];
    }

    const {
      messageEvents,
      todoEvents,
    } = eventsWithSchedule;

    const notifications = messageEvents.concat(todoEvents)
      .filter((event) => event.notification && _.isEmpty(event.completedDate) && eventPhaseIsActive(event, programPhase))
      .filter((event) => (event.repeating ? repeatEventIsActive(event) : eventIsActive(event)));

    return notifications;
  }, [eventsWithSchedule, programPhase]);

  return {
    activeTodos,
    clinicalContact,
    completedEvents,
    eventsWithSchedule,
    futureTodos,
    localNotifications,
    isLoading,
    programPhase,
    todoCount,
    unread,
  };
}
