import { useMemo } from 'react';
import { useGetPatientQuery } from '../api/patientApi';
import { flattenEducationList } from '../helpers/education_helpers';
import {
  useGetEducationQuery,
  useLazyGetEducationQuery,
} from '../api/educationApi';

export default function useEducation(options = { skip: false }) {
  const { data: patient } = useGetPatientQuery(undefined, { skip: options.skip });

  const { data: education } = useGetEducationQuery(undefined, {
    refetchOnMountOrArgChange: 3600,
    skip: options.skip 
  });
  const [educationForSurvey] = useLazyGetEducationQuery();

  const allEducation = useMemo(() => {
    if (!education) return [];
    return education;
  }, [education]);

  const educationForProfile = useMemo(() => {
    if (!patient || !allEducation) return [];

    const { profile } = patient;

    return flattenEducationList(allEducation, profile);
  }, [patient, allEducation]);

  const getEducationForSurvey = async (id: number | undefined) => {
    const results = await educationForSurvey(id, true);
    if (results.isSuccess) {
      return results.data;
    }
    return [];
  };

  return {
    allEducation,
    educationForProfile,
    getEducationForSurvey,
  };
}
