import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import LoaderInline from '../../layout/LoaderInline';
import { Modal } from '../../layout';
import { useAppSelector } from '../../../redux/hooks';
import {
  useSendTwoFACodeMutation,
  useSetupTwoFAMutation,
} from '../../../patient-app-common/api/authApi';
import { useUpdatePatientMutation } from '../../../patient-app-common/api/patientApi';
import { isPhone } from '../../../patient-app-common/utils';


export default function SetTwoFA({
  visible, 
  handleExit,
  mfaType,
  fullScreen = false,
  dismissible = true,
}) {
  return (
    <Modal
      visible={visible}
      fullScreen={fullScreen}
      dismissable={dismissible}
      component={(
        <TwoFAForm
          handleExit={handleExit}
          mfaType={mfaType}
        />
      )}
      handleCloseClick={handleExit}
    />
  );
}

function TwoFAForm({ handleExit, mfaType }) {
  const { t } = useTranslation('settings');

  const { mfaContact } = useAppSelector(({ auth }) => auth);

  const [updatePatient, { isLoading }] = useUpdatePatientMutation();
  const [sendTwoFACode, { isLoading: isLoadingSend }] = useSendTwoFACodeMutation();
  const [setupTwoFA, { isLoading: isLoadingSetup }] = useSetupTwoFAMutation();
  const [timer, setTimer] = useState(30);

  const [showEdit, setShowEdit] = useState(!_.isEmpty(mfaContact))
  const [page, setPage] = useState(1);

  const [form, setForm] = useState({
    mfaContact: '',
    method: 'sms',
  });

  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (timer === 0) {
      setTimer(null);
    }

    if (!timer) return;

    const timerId = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timer]);

  const disable2FA = async () => {
    try {
      const result = await updatePatient({
        mfaChannel: null,
        mfaContact: null,
      }).unwrap();

      if (result.success) {
        handleExit();
      } else {
        setError(t('common.error.not_saved'));
      }
    } catch (_e) {
      setError(t('common.error.not_saved'));
    }
  };

  const handleInputChange = (e) => {
    setError('');

    const { name, value } = e.target;

    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateContact = (form) => {
    const { mfaContact } = form;
    
    if (!mfaContact) {
      setError(t('common.error.blank_input'));
      return false;
    }

    const validPhone = isPhone(mfaContact);
    let errors = ''

    if (!validPhone) {
      errors = (`${t('common.error.invalid_phone')}\n`);
    }

    setError(errors);
    return validPhone;
  };

  const sendCode = async () => {
    setTimer(30);

    try {
      const request = await sendTwoFACode(form).unwrap();

      if (!request.success) {
        setError(t('settwofa.error.send_code_failed'));
      }

      return request.success;
    } catch (_e) {
      setError(t('settwofa.error.send_code_failed'));
      return false;
    }
  };

  const startValidation = async () => {
    const isValid = validateContact(form);

    if (isValid) {
      const result = await sendCode();

      if (result) {
        setPage(2);
      }
    }
  };

  const checkVerification = async () => {
    try {
      const result = await setupTwoFA({
        ...form,
        code,
      }).unwrap();

      if (result.success) {
        handleExit();
      }
    } catch (_e) {
      setError(t('settwofa.error.verification_failed'));
    }
  };

  const btnText = useMemo(() => {
    let text = t('common.two_fa.button_resend');
    if (timer) {
      text += ` (${timer}s)`;
    }
    return text;
  }, [timer]);


  if (showEdit) {
    return (
      <>
        <ContentDiv>
          <Title>{t('settwofa.title_edit')}</Title>
          <p className="py-4">   
            {t('settwofa.description_edit', { number: mfaContact })}
            {mfaType === "optional" ? (
              ` ${t('settwofa.text_disable_true')}`
            ): (
              ` ${t('settwofa.text_disable_false')}`
            )}
          </p>
        </ContentDiv>
        {error && <p className="error-text pb-4">{error}</p>}
        <div className="flex flex-row">
          <button
            className="btn-secondary"
            onClick={() => setShowEdit(false)}
          >
            {t('settwofa.button.edit')}
          </button>
          {mfaType === 'optional' && (
            <button
              className="btn-danger ml-4"
              onClick={disable2FA}
            >
              {t('settwofa.button.disable')}
            </button>
          )}
        </div>
        {isLoading && <div><LoaderInline /></div>}
      </>
    );
  }

  return (
    <div>
      {page === 1 && (
        <>
          <ContentDiv>
            <Title>{t('settwofa.page_one.title')}</Title>
            <div className="py-4">
              <p>
                {t('settwofa.page_one.description')}
              </p>
            </div>

            {error && <p className="error-text pb-4 whitespace-pre-line">{error}</p>}

            <Label htmlFor="mfaContact">
              {t('common.text.phone')}
              <Input
                autoComplete="off"
                error={!_.isEmpty(error)}
                id="mfaContact"
                name="mfaContact"
                onChange={(e) => handleInputChange(e)}
              />
            </Label>

            <div className="flex-row item mt-2">
              <Radio
                id="sms"
                name="method"
                type="radio"
                checked={form.method === 'sms'}
                onChange={(e) => handleInputChange(e)}
                value="sms"
              />
              <label htmlFor="sms" className="font-bold align-middle">
                {t('common.two_fa.radio_sms')}
              </label>

              <Radio
                className="ml-8"
                id="call"
                name="method"
                type="radio"
                checked={form.method === 'call'}
                onChange={(e) => handleInputChange(e)}
                value="call"
              />
              <label htmlFor="call" className="font-bold align-middle">
                {t('common.two_fa.radio_call')}
              </label>
            </div>
          </ContentDiv>
          <button
            className="btn-primary w-1/3 mt-4"
            onClick={startValidation}
          >
            {t('common.action.next')}
          </button>
          {isLoadingSend && <div><LoaderInline /></div>}
        </>
      )}

      {page === 2 && (
        <>
          <ContentDiv>
            <Title>{t('settwofa.page_two.title')}</Title>
            <p className="py-4">
              {t('settwofa.page_two.description', { number: form.mfaContact.slice(-4) })}
            </p>

            {error && <p className="error-text pb-4 whitespace-pre-line">{error}</p>}
            
            <div>
              <Label htmlFor="code">
                {t('common.two_fa.input_code')}
                <Input
                  autoComplete="off"
                  error={!_.isEmpty(error)}
                  id="code"
                  name="code"
                  onChange={(e) => setCode(e.target.value)}
                />
              </Label>

              <div className="mt-4">
                <p>{t('common.two_fa.change_method')}</p>
                <div className="flex-row item ">
                  <label htmlFor="sms" className="font-bold align-middle">
                    {t('common.two_fa.radio_sms')}
                  </label>
                  <Radio
                    className="ml-2"
                    id="sms"
                    name="method"
                    type="radio"
                    checked={form.method === 'sms'}
                    onChange={(e) => handleInputChange(e)}
                    value="sms"
                  />
                  <label htmlFor="call" className="font-bold align-middle ml-8">
                    {t('common.two_fa.radio_call')}
                  </label>
                  <Radio
                    className="ml-2"
                    id="call"
                    name="method"
                    type="radio"
                    checked={form.method === 'call'}
                    onChange={(e) => handleInputChange(e)}
                    value="call"
                  />
                  <Btn
                    className="ml-8"
                    disabled={timer}
                    onClick={sendCode}
                  >
                    {btnText}
                  </Btn>
                </div>
              </div>

            </div>
          </ContentDiv>
          <button
            onClick={checkVerification}
            className="btn-primary"
          >
            {t('common.action.submit')}
          </button>
          {isLoadingSetup && <div><LoaderInline /></div>}
        </>
      )}
    </div>
  );
}

const ContentDiv = styled.div(() => [
  css`min-height: 40vh`,
]);

const Title = styled.p(() => [
  tw`text-lg text-left`,
]);

const Label = styled.label(() => [
  tw`block font-bold`,
]);

const Input = styled.input(({ error }) => [
  tw`form-input border-black block w-full`,
  error && tw`border-red`,
]);

const Radio = styled.input(() => [
  tw`form-radio text-blue-100 mr-2`,
  tw`border-2 border-black`,
]);

const Btn = styled.button(({ disabled }) => [
  tw`bg-blue-10 text-blue-100 text-sm`,
  tw`my-4 py-2 px-4 rounded-full`,
  disabled && tw`text-gray-500 bg-gray-200`,
]);
