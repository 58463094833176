import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';
import { isIE } from 'react-device-detect';
import {  useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks';
import { LoaderInline } from '../layout';
import OTALoginCard from './layout/OTALoginCard';
import useHospitalFlags from '../../hooks/useHospitalFlags';
import { useLoginMutation } from '../../patient-app-common/api/authApi';
import { useUpdatePatientMutation } from '../../patient-app-common/api/patientApi';
import { validateTestServer } from '../../patient-app-common/utils';
import { setRoute } from '../../patient-app-common/slices/appSlice';
import ReCaptcha from './ReCaptcha';

const image = `${process.env.PUBLIC_URL}/assets/images/launch.png`;

type LanguageType = {
  en: number,
  fr: number,
}

// NOTE: this is hardcoded to CA server (fix on rails side)
const LANGUAGE_IDS: LanguageType = {
  en: 1,
  fr: 6,
};

export default function Login() {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation('login');

  const {
    hospital,
    hasOTALink,
    hasSignup,
    langSelector,
  } = useHospitalFlags();

  const [login, { isLoading }] = useLoginMutation();
  const [updatePatient] = useUpdatePatientMutation();

  const errorDiv = useRef<HTMLDivElement>(null);

  const { testEnv } = useAppSelector(({ app }) => app);
  const { token } = useAppSelector(({ auth }) => auth);

  const [showPwd, toggleShowPwd] = useState(false);
  const [nameError, setNameError] = useState('');
  const [pwdError, setPwdError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [testServer, setTestServer] = useState('ca');
  const [selectedLang, setSelectedLang] = useState('en');
  const [isHuman, setIsHuman] = useState(true);

  const [state, setState] = React.useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (!testEnv || !testServer) return;

    const route = validateTestServer(testServer);

    if (route) {
      dispatch(setRoute(route));
    }
  }, [testEnv, testServer]);

  useEffect(() => {
    if (nameError || pwdError || loginError) {
      errorDiv.current && errorDiv.current.focus();
    }
  }, [nameError, pwdError, loginError]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateInput = () => {
    setNameError('');
    setPwdError('');

    if (!state.email.length) {
      setNameError(t('login.error.blank_user'));
      return false;
    }

    if (!state.password.length) {
      setPwdError(t('login.error.blank_password'));
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const validString = validateInput();

    if (!validString) {
      return;
    }

    const params = {
      username: state.email,
      pin: state.password,
    };

    if (!isHuman) {
      setLoginError(t('common.error.recaptcha'));
      return;
    }

    setLoginError('');

    try {
      const results = await login(params).unwrap();

      if (results.token && langSelector) {
        const languageId = LANGUAGE_IDS[selectedLang as keyof LanguageType];
        await updatePatient({ languageId });
      }
    } catch (error: any) {
      if (error?.status === 404) {
        setLoginError(t('login.error.unauthorized'));
      } else {
        setLoginError(t('common.error.server'));
      }
    }
  };

  const openHelp = () => {
    const url = `https://ca.seamless.md/education/241780?lang=${selectedLang}`;
    window.open(url, '_blank');
  };

  React.useEffect(() => {
    if (langSelector) {
      const locale = 'fr';
      setSelectedLang(locale);
    }
    
  }, [langSelector]);

  useEffect(() => {
    i18n.changeLanguage(selectedLang);
    document.documentElement.lang = selectedLang;
    
  }, [selectedLang]);

  if (token) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="bg-gray-100 h-screen">
      <Container>
        <TextColumn>
          <FormDiv role="form">
            {hasOTALink ? (<OTALoginCard />) : (
              <div className="flex justify-center p-4" role="banner">
                {hospital?.logo && <Logo alt="logo" src={hospital.logo} />}
              </div>
            )}

            <div className="pb-12">
              <h1 className="text-center text-xl">
                {t('login.form.title')}
              </h1>
            </div>

            {(nameError || pwdError || loginError) && (
              <div ref={errorDiv} role="alert" tabIndex={0}>
                <p className="error-text text-sm">
                  {nameError || pwdError || loginError}
                </p>
              </div>
            )}

            <div className="block pb-4 text-left">
              <label htmlFor="email">
                {t('login.input.user')}
              </label>
              <Input
                autoComplete="off"
                error={nameError}
                id="email"
                name="email"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                type="email"
                value={state.email}
              />
            </div>

            <div className="block text-left pb-1">
              <label htmlFor="password">
                {t('common.input.password')}
              </label>
              <Input
                autoComplete="new-password"
                error={pwdError}
                id="password"
                name="password"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                type={showPwd ? 'text' : 'password'}
                value={state.password}
              />
            </div>

            <div className="inline pb-4 w-full">
              <Checkbox
                id="show-pwd"
                type="checkbox"
                onChange={() => toggleShowPwd(!showPwd)}
              />
              <label htmlFor="show-pwd">
                {t('login.toggle.password')}
              </label>
            </div>

            {testEnv && (
              <div className="block text-left pt-4 pb-1">
                <label htmlFor="server">Server</label>
                <Input
                  id="server"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setTestServer(e.target.value)}
                  value={testServer}
                />
              </div>
            )}

            {langSelector && (
              <div className="block text-left pt-4 pb-1">
                <select
                  name="lng"
                  id="lng"
                  value={selectedLang}
                  onChange={(e) => setSelectedLang(e.target.value)}
                  className="form-select w-full"
                >
                  <option value="en">English</option>
                  <option value="fr">Français</option>
                </select>
              </div>
            )}

            <ReCaptcha
              setIsHuman={(result: boolean) => setIsHuman(result)}
            />

            <div className="p-4 mt-4">
              <LoginBtn
                className="btn-primary"
                disabled={isLoading}
                onClick={(e: ChangeEvent<HTMLInputElement>) => handleSubmit(e)}
              >
                {t('common.action.login')}
              </LoginBtn>
            </div>

            {hasSignup && (
              <div className="p-4">
                <SignupBtn to="/signup" className="btn-secondary">
                  {t('common.link.signup')}
                </SignupBtn>
              </div>
            )}

            {isLoading && (
              <div className="text-center">
                <LoaderInline />
              </div>
            )}

            <div>
              <div className="flex justify-center">
                <StyledLink to="/request_password">
                  {t('common.link.help_account')}
                </StyledLink>
              </div>

              <div className="flex justify-center">
                <StyledLink onClick={openHelp} to="#">
                  {t('common.link.help')}
                </StyledLink>
              </div>
            </div>
          </FormDiv>
        </TextColumn>

        <ImgColumn>
          <Img alt={t('common.image.cover_alt')} src={image} />
        </ImgColumn>
      </Container>
    </div>
  );
}

const Container = styled.div(() => [
  tw`bg-white h-screen`,
  tw`flex flex-col md:flex-row`,
  css`
    max-height: -webkit-fill-available;
  `,
]);

const TextColumn = styled.div(() => [
  tw`items-center`,
  tw`flex-none md:flex flex-col justify-center`,
  tw`w-full md:w-1/2`,
]);

const ImgColumn = styled.div(() => [
  tw`w-0 md:w-1/2`,
  tw`flex-none md:flex items-center`,
]);

const Img = styled.img(() => [
  isIE && tw`w-full`,
]);

const Logo = styled.img(() => [
  tw`object-scale-down`,
  css`width: 10rem;`,
]);

const FormDiv = styled.form(() => [
  tw`bg-white border p-12 rounded-lg shadow-lg`,
  tw`min-w-full sm:min-w-logincolumn`,
  tw`mx-auto my-0 sm:mt-8`,
  tw`text-sm sm:text-base`,
]);

const Input = styled.input(({ error }: { error: boolean }) => [
  tw`block form-input mt-1 w-full`,
  tw`hover:border-blue-100`,
  error && tw`border-red`,
]);

const Checkbox = styled.input(() => [
  tw`form-checkbox h-4 text-blue-100 w-4 mx-3`,
  tw`border-2 border-black`,
]);

const LoginBtn = styled.button(({ disabled }: { disabled: boolean }) => [
  tw`w-full`,
  disabled && tw`bg-gray-500`,
]);

const SignupBtn = styled(Link)(() => [
  tw`block text-center w-full`,
]);

const StyledLink = styled(Link)(() => [
  tw`text-blue-100 text-center`,
  tw`hover:underline`,
]);
