import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import { ImSpinner8 } from 'react-icons/im';
import { getURLParam } from '../../utils/utils';
import { setProviderMode, setToken } from '../../patient-app-common/slices/authSlice';
import { useAppSelector } from '../../redux/hooks';
import { useAutoAuthMutation } from '../../patient-app-common/api/authApi';

export default function AutoAuth() {
  const { route } = useAppSelector(({ app }) => app);
  const { token: savedToken } = useAppSelector(({ auth }) => auth);

  const { t } = useTranslation('login');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { token, eventId } = useParams<{ token: string, eventId: string}>();
  const [autoAuth] = useAutoAuthMutation();

  const [error, setError] = useState('');

  const getURL = () => {
    const mode = getURLParam(location.search, 'mode');
    let pathname = '/';
    let state = {};

    switch (mode) {
      case 'login':
        pathname = '/';
        break;
      case 'education': {
        const eduID = getURLParam(location.search, 'education_id');
        pathname = `/education/${eduID}`;
        break;
      }
      case 'survey': {
        const procedureID = getURLParam(location.search, 'procedure_id');
        pathname = `/tracker/${procedureID}`;

        if (eventId !== '0') {
          state = { eventId };
        }
        break;
      }
      case 'provider': {
        const trackerID = getURLParam(location.search, 'tracker');
        pathname = `/tracker/${trackerID}`;
        if (eventId) {
          state = { eventId };
        }
        break;
      }

      default:
    }

    return { pathname, state };
  };

  useEffect(() => {
    if (!token) return;
    dispatch(setToken(token));
  }, [token]);

  useEffect(() => {
    const providerMode = getURLParam(location.search, 'mode') === 'provider';

    if (providerMode) {
      dispatch(setProviderMode(true));
    }
  }, [dispatch, location.search]);

  const getErrorMessage = (fetchError: FetchBaseQueryError) => {
    switch (fetchError.status) {
      case 401:
        return t('autoauth.error.invalid');

      case 'FETCH_ERROR':
      case 'TIMEOUT_ERROR':
        return t('common.error.fetch');

      case 'PARSING_ERROR':
        return t('common.error.server');

      default:
        return t('common.error.server');
    }
  };

  const verifyToken = async () => {
    setError('');
    let params = {}
    const { pathname, state } = getURL();

    try {
      const result = await autoAuth(params).unwrap();

      if (result.success) {
        navigate(pathname, { state });
      } else {
        catchError(result.error)
      }
    } catch(e) {
      catchError(e);
    }
  };

  const catchError = (error: unknown) => {
    const hasError: unknown = error;
    const fetchError: FetchBaseQueryError = hasError as FetchBaseQueryError;
    setError(getErrorMessage(fetchError));
  }

  useEffect(() => {
    if (route === null || !savedToken) return;
    verifyToken();
  }, [route, savedToken]);

  if (error) {
    return (
      <Container>
        <ContentDiv>
          <h1 className="text-center">{error}</h1>
          <div className="mx-auto my-4">
            <button className="btn-secondary" onClick={() => verifyToken()}>
              {t('common.action.retry')}
            </button>
          </div>
        </ContentDiv>
      </Container>
    );
  }

  return (
    <Container>
      <ContentDiv>
        <SpinnerDiv aria-busy="true" role="status">
          <span className="text-6xl text-blue-100">
            <ImSpinner8 className="animate-spin" />
          </span>
        </SpinnerDiv>
        <h1>{t('autoauth.title')}</h1>
      </ContentDiv>
    </Container>
  );
}

const Container = styled.div(() => [
  tw`bg-white flex flex-row h-screen justify-center`,
  css`
    max-height: -webkit-fill-available;
  `,
]);

const ContentDiv = styled.div(() => [
  tw`flex flex-col justify-center text-xl`,
]);

const SpinnerDiv = styled.div(() => [
  tw`flex justify-center p-8`,
]);
