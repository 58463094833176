import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import AnswerAlert from './layout/AnswerAlert';
import OpioidReview from './opioid/OpioidReview';
import Review from './review/Review';
import QuestionType from './questions/QuestionType';
import SurveyLayout from './layout/SurveyLayout';
import SurveyLoader from './layout/SurveyLoader';
import { surveyHelperContext } from '../../patient-app-common/helpers/survey_helper';
import useSurveyDrafts from '../../hooks/useSurveyDrafts';
import Modal from '../layout/Modal';
import useModal from '../../hooks/useModal';
import useSegment from '../../hooks/useSegment';

const OPEN_EVENT = 'Survey Opened';
const CLOSE_EVENT = 'Survey Closed';

export default function Survey({
  draft,
  eventId,
  opioidSurvey,
  patientDates,
  patient,
  surveyData,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { sanitizeProfile, trackSurvey } = useSegment();
  const { saveDraft } = useSurveyDrafts();

  const { visible, toggleModal } = useModal();

  const { profile, medicineHistory } = patient;

  const { questions } = surveyData;
  const surveyName = surveyData.mainTracker ? t('common.text.main_tracker') : surveyData.patientDisplayName;

  const [surveyHelper, setSurveyHelper] = useState(null);
  const [answers, setAnswers] = useState({});

  const [profilesHistory, setProfileHistory] = useState([profile]);
  const [questionHistory, setQuestionHistory] = useState([0]);
  const index = _.last(questionHistory);
  const progress = _.round(((index + 1) / questions.length) * 100);

  const [loader, setLoader] = useState(false);
  const [review, showReview] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    trackSurvey(OPEN_EVENT, {
      id: eventId, 
      profile: sanitizeProfile(profile),
    }, surveyData);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [questionHistory]);

  useEffect(() => {
    const helper = surveyHelperContext({ patientDates, surveyData, editMode });
    setSurveyHelper(helper);
  }, [editMode, patientDates, surveyData]);

  useEffect(() => {
    if (!draft) return;

    const {
      draftHistory,
      draftAnswers,
      draftProfiles,
    } = draft;

    setQuestionHistory(draftHistory);
    setAnswers(draftAnswers);
    setProfileHistory(draftProfiles);

    if (_.last(draftHistory) === 0 && draftHistory.length > 1) {
      showReview(true);
    }
  }, [draft]);

  const getAnswer = (value) => {
    const answersClone = { ...answers };
    const questionID = questions[index].id;
    answersClone[questionID] = value;
    setAnswers(answersClone);
  };

  const handleNextClick = () => {
    const questionType = questions[index].type;
    const answer = answers[questions[index].id];

    const valid = surveyHelper.validateAnswer(questionType, answer);

    if (!valid) {
      toggleModal();
      return;
    }

    const { validQuestions, validProfiles, validAnswers } = surveyHelper.validateQuestions({
      currentIndex: index,
      currentAnswers: answers,
      currentHistory: questionHistory,
      currentProfiles: profilesHistory,
    });

    const nextIndex = _.last(validQuestions);

    if (nextIndex === 0) {
      showReview(true);
    }

    setProfileHistory([...validProfiles]);
    setQuestionHistory([...validQuestions]);
    setAnswers(validAnswers);
  };

  const handleBackClick = () => {
    const initProfilesHistory = _.initial([...profilesHistory]);
    setProfileHistory(initProfilesHistory);

    const initQuestionHistory = _.initial([...questionHistory]);
    setQuestionHistory(initQuestionHistory);
  };

  const handleEdit = (editIndex) => {
    showReview(false);
    setEditMode(true);

    const pos = questionHistory.indexOf(editIndex);

    const shallowProfiles = profilesHistory.slice(0, pos + 1);
    setProfileHistory(shallowProfiles);

    const shallowQuestions = questionHistory.slice(0, pos + 1);
    setQuestionHistory(shallowQuestions);
  };

  const handleSaveAndExit = async () => {
    trackSurvey(CLOSE_EVENT, { id: eventId, progress }, surveyData);

    const surveyNotStarted = _.isEmpty(answers);

    if (opioidSurvey || surveyNotStarted) {
      return navigate('/');
    }

    setLoader({ text: t('survey.load.update') });

    try {
      await saveDraft({
        answers,
        eventId,
        surveyData,
      });
      navigate('/');
    } catch (e) {
      setLoader({
        text: t('survey.load.error'),
        type: 'error',
      });
    }
  };

  if (loader) {
    return (
      <SurveyLoader
        text={loader.text}
        type={loader.type}
        handleCancelClick={() => setLoader(null)}
      />
    );
  }

  if (review) {
    return (
      <>
        {!opioidSurvey && (
          <Review
            answers={answers}
            draft={draft}
            eventId={eventId}
            exitHandler={handleSaveAndExit}
            handleEdit={handleEdit}
            medicineHistory={medicineHistory}
            profile={_.last(profilesHistory)}
            questions={questions}
            surveyData={surveyData}
          />
        )}

        {opioidSurvey && (
          <OpioidReview
            answers={answers}
            backHandler={() => {
              showReview(false);
              handleBackClick();
            }}
            eventId={eventId}
            procedureName={patient.procedureName}
            profile={_.last(profilesHistory)}
            questions={questions}
            surveyData={surveyData}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        component={<AnswerAlert handleCloseClick={toggleModal} />}
      />
      <SurveyLayout
        backHandler={handleBackClick}
        disableClose={surveyData.setupSurvey}
        exitHandler={handleSaveAndExit}
        index={index}
        nextHandler={handleNextClick}
        progress={progress}
        showBack={!editMode}
        surveyName={surveyName}
        question={questions[index]}
      >
        <QuestionType
          answers={answers}
          getAnswer={getAnswer}
          index={index}
          medicineHistory={medicineHistory}
          questions={questions}
        />
      </SurveyLayout>
    </>
  );
}
