import { useCallback, useMemo } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useGetPatientQuery } from '../patient-app-common/api/patientApi';
import { useAppSelector } from '../redux/hooks';
import usePatientDates from '../patient-app-common/hooks/usePatientDates';
import { patientProgramPhase } from '../patient-app-common/helpers/appevent_helpers';

const EMPTY_IP = { context: { ip: '0.0.0.0' } };
const prependServerToParam = (server, param) => `${server}-${param}`;

export default function useSegment() {
  const { identify, track, page } = window.analytics;
  const { patientId } = useAppSelector(({ auth }) => auth);

  const { patientDates } = usePatientDates({ skip: !patientId });

  const { data: patient } = useGetPatientQuery(undefined, { skip: !patientId });

  const trackPage = (path) => {
    page(path, { path }, EMPTY_IP);
  }

  const phaseParams = useMemo(() => {
    if (!patient || !patientDates) return {};

    const {
      daysPostOp,
      hasPeriop,
      hasPostop,
      maternityProgram,
      nonSurgicalProgram,
      timezone,
    } = patient;

    const programPhase = patientProgramPhase(patientDates, daysPostOp);
    const { signupDate, surgeryDate, dischargeDate } = patientDates;

    const preopOnly = !hasPeriop && !hasPostop;

    if (nonSurgicalProgram || preopOnly) {
      const daysAfterSignup = moment().diff(signupDate, 'days');

      return {
        phase: 'A',
        daysInPhase: daysAfterSignup,
      };
    }

    switch (programPhase) {
      case 'inPreop': {
        const hasSurgeryDate = moment(patientDates.surgeryDate).isValid();
        let label;

        if (hasSurgeryDate) {
          label = maternityProgram ? 'B' : 'C';
        } else {
          label = 'D';
        }

        const daysToSurgery = moment(surgeryDate)
          .diff(moment.tz(timezone).startOf('day'), 'days');

        return {
          phase: label,
          daysInPhase: daysToSurgery,
        };
      }

      case 'inPeriop': {
        let label;

        if (hasPeriop) {
          label = maternityProgram ? 'E' : 'F';
        } else {
          label = 'G';
        }

        const daysAfterSurgery = moment().diff(surgeryDate, 'days');

        return {
          phase: label,
          daysInPhase: daysAfterSurgery,
        };
      }

      case 'inPostop':
      case 'end': {
        const daysAfterDischarge = moment().diff(dischargeDate, 'days');

        return {
          phase: 'H',
          daysInPhase: daysAfterDischarge,
        };
      }

      default:
        return {};
    }
  }, [patient, patientDates]);

  const baseParams = useMemo(() => {
    if (!patient) return {};

    const {
      createdAt,
      completedDate,
      demo,
      hospitalId,
      procedureSetId,
      procedureSetInvariant,
      server,
    } = patient;

    const params = {
      createdAt: moment(createdAt).format(),
      demo,
      hospitalID: prependServerToParam(server, hospitalId),
      procedureSetID: prependServerToParam(server, procedureSetId),
      procedureSetInvariantID: prependServerToParam(server, procedureSetInvariant),
      programCompleteDate: moment(completedDate).format(),
      server,
    };

    return params;
  }, [patient]);

  const createCustomSegmentParams = (params) => {
    const paramsCopy = { ...params };

    if (_.has(params, 'id')) {
      paramsCopy.id = [prependServerToParam(baseParams.server, params.id)];
      paramsCopy.relativeID = paramsCopy.id;
    }

    const paramsToSend = {
      ...paramsCopy,
      ...phaseParams,
      ...baseParams,
    };

    return paramsToSend;
  };

  const trackUser = (userID) => {
    identify(userID, baseParams, EMPTY_IP);
  };

  const sendTrackEvent = (eventName, params = {}) => {
    const paramsToSend = createCustomSegmentParams(params);
    track(eventName, paramsToSend, EMPTY_IP);
  };

  const sanitizeProfile = (profile) => {
    const sanitized = []

    for (const [keyName, keyProfile] of Object.entries(profile)) {
      const { options, value } = keyProfile;
      let indexOfValue = null;
      if (options.includes(value)) {
        indexOfValue = options.indexOf(value);
      }
      sanitized.push({ [keyName]: indexOfValue });
    }

    return sanitized;
  }

  const trackSurvey = useCallback((eventName, params, surveyData) => {
    if (!patient || !patientDates) return;

    const { dischargeDate } = patientDates;
    const { server, procedureSetId } = patient;

    // 'id' is application event id and replaced if it is a main tracker, setup or end survey
    let { id } = params;

    if (surveyData.mainTracker) {
      const daysAtHome = moment().diff(moment(dischargeDate), 'days');
      id = `HC${daysAtHome}`;
    }
    if (surveyData.setupSurvey) {
      id = 'setup_survey';
    }
    if (surveyData.mainSurvey) {
      id = 'main_survey';
    }

    const paramsCopy = {
      ...params,
      id: [id],
      title: surveyData.patientDisplayName,
      procedureInvariantID: surveyData.rootId,
      procedureID: surveyData.id,
      surveyID: `${server}-${id}_${server}-${surveyData.id}_${server}-${procedureSetId}_${patientId}`,
      healthcheck: surveyData.mainTracker,
      setupSurvey: surveyData.setupSurvey,
    };

    sendTrackEvent(eventName, paramsCopy);
  }, [patient, patientDates]);

  return {
    sanitizeProfile,
    sendTrackEvent,
    trackPage,
    trackUser,
    trackSurvey,
  };
}
