import { parse } from 'node-html-parser';
import React, {
  ReactInstance,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import tw, { css, styled } from 'twin.macro';
import { isIE } from 'react-device-detect';
import { useReactToPrint } from 'react-to-print';
import { BiPrinter } from 'react-icons/bi';
import EduDrawer from './layout/EduDrawer';
import Breadcrumb from './layout/BreadCrumb';
import TextSizePanel from './layout/TextSizePanel';
import EduFooter from './layout/EduFooter';
import { NavBar, NoMatch } from '../layout';
import '../../styles/ed-v2.scss';
import '../../styles/ed-v3.scss';
import useSegment from '../../hooks/useSegment';
import useEducation from '../../patient-app-common/hooks/useEducation';
import { educationParams } from '../../patient-app-common/helpers/education_helpers';

const EDUCATION_EVENT = 'Education Topic Viewed';

export default function EduTopic() {
  const { componentID } = useParams<{componentID: string}>();
  const { t } = useTranslation();
  const { sendTrackEvent } = useSegment();

  const mainRef = useRef<HTMLDivElement | null>(null);
  const printRef = useRef<ReactInstance>(null);

  const [collapse, toggleCollapse] = useState(false);
  const [textSize, setTextSize] = useState('1rem');

  const handlePrintClick = useReactToPrint({
    content: () => printRef.current,
  });

  const { educationForProfile } = useEducation();

  const eduTopic = useMemo(() => {
    if (!educationForProfile?.length || !componentID) return null;
    return educationParams(educationForProfile, _.toNumber(componentID));
  }, [educationForProfile, componentID]);

  const html = useMemo(() => {
    if (!eduTopic?.html) return '';

    const root = parse(eduTopic.html);
    const linkElements = root.querySelectorAll(('a[href*="seamless.md/#/education/"]'));
    linkElements.forEach((element) => {
      element.removeAttribute('target');
    })

    return root.toString();
  }, [eduTopic]);

  useEffect(() => {
    if (!eduTopic) return;

    sendTrackEvent(EDUCATION_EVENT, {
      title: eduTopic?.title,
      id: componentID,
      source: 'EduTopic',
    });
  }, [eduTopic]);

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollTop = 0;
    }
  }, [componentID]);

  if (!componentID || !educationForProfile?.length) {
    return null;
  }

  if (!eduTopic?.title) {
    return <NoMatch />;
  }

  return (
    <div>
      <Helmet>
        <style>{'body { overflow: hidden; }'}</style>
      </Helmet>
      <NavBar skipHandler={() => mainRef.current && mainRef.current.focus()} />
      <Container>
        <EduDrawer
          collapse={collapse}
          componentID={componentID}
          education={educationForProfile}
          skipHandler={() => mainRef.current && mainRef.current.focus()}
          toggleCollapse={toggleCollapse}
        />

        <TopicsContainer
          aria-label={t('edutopic.arialabel')}
          collapse={collapse}
          ref={mainRef}
          role="region"
          tabIndex={0}
        >
          <Card>
            <Breadcrumb education={educationForProfile} componentID={componentID} />

            {!_.isEmpty(html) && (
              <>
                <div className="flex justify-end p-4">
                  <TextSizePanel sendTextSize={(value) => setTextSize(value)} />

                  <div>
                    {!isIE && (
                      <PrintBtn onClick={handlePrintClick} className="p-3">
                        <span className="place-self-center">
                          <BiPrinter className="text-xl" />
                        </span>
                        <span className="place-self-center">
                          {t('common.action.print')}
                        </span>
                      </PrintBtn>
                    )}
                  </div>
                </div>

                <EduContainer
                  className="ed-v3"
                  textSize={textSize}
                  dangerouslySetInnerHTML={{ __html: html }}
                  ref={printRef}
                />
              </>
            )}

            {eduTopic.subtopics && eduTopic.subtopics.length > 0 && (
              <div className="p-6">
                <div className="py-3">
                  <h1 className="text-2xl">{eduTopic.title}</h1>
                </div>
                <ul className="divide-y p-3">
                  {eduTopic.subtopics.map((component) => (
                    <li
                      key={component.id}
                      className="p-1"
                    >
                      <Link to={`/education/${component.id}`}>
                        <LinkText>{component.title}</LinkText>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <EduFooter education={educationForProfile} componentID={componentID} />
          </Card>
        </TopicsContainer>
      </Container>
    </div>
  );
}

const Container = styled.div(() => [
  tw`bg-gray-100 h-full`,
  css`
    padding-top: 3.875rem;
  `,
  tw`overflow-hidden`,
]);

const TopicsContainer = styled.div(({ collapse }: { collapse: boolean }) => [
  tw`float-right`,
  tw`h-screen overflow-scroll pb-12`,
  collapse ? tw`w-full` : tw`w-full md:w-edutopic`,
  css`
    max-width: 1280px;
    max-height: -webkit-fill-available;
  `,
]);

const EduContainer = styled.div(({ textSize }: { textSize: string }) => [
  tw`p-6`,
  css`
    font-size: ${textSize};
  `,
]);

const Card = styled.div(() => [
  tw`bg-white border-gray-500 rounded-lg shadow-lg`,
  tw`m-0 sm:mx-24 sm:my-12`,
]);

const PrintBtn = styled.button(() => [
  tw`bg-white border border-blue-100 inline-flex ml-4 px-4 text-blue-100 rounded`,
  tw`hover:bg-teal-10`,
]);

const LinkText = styled.span(() => [
  tw`text-blue-100 text-lg`,
  tw`hover:font-bold`,
]);
