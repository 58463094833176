/* eslint-disable import/prefer-default-export */
export const DOMAINS = {
  LOCAL: [
    'localhost',
    'test-hospital.localhost',
  ],

  SANDBOX: [
    'sandbox-app.seamless.md',
    'sandbox-app.seamlessmd.app',
  ],

  CA: [
    'app-beta.seamless.md',
    'app-beta-2.seamless.md',
    'bchs.seamless.md',
    'beta.seamless.md',
    'boehringer.seamless.md',
    'ca-beta.seamless.md',
    'ca-beta-fr.seamless.md',
    'ca-demo1.seamless.md',
    'ca-demo2.seamless.md',
    'ca-demo3.seamless.md',
    'ca-demo-cs1.seamless.md',
    'ca-demo-cs2.seamless.md',
    'ca-demo-sales1.seamless.md',
    'ca-demo-sales2.seamless.md',
    'ca-qa.seamless.md',
    'ca-support.seamless.md',
    'ca-templates.seamless.md',
    'cch.seamless.md',
    'ckha.seamless.md',
    'cmh.seamless.md',
    'cmhafortfrances.seamless.md',
    'covid19.seamless.md',
    'bluewater.seamless.md',
    'demo.seamless.md',
    'downsview.seamless.md',
    'duval.seamless.md',
    'grandriver.seamless.md',
    'haltonhealthcare.seamless.md',
    'hccss.seamless.md',
    'holland.seamless.md',
    'horizonhealth.seamless.md',
    'humber.seamless.md',
    'khsc.seamless.md',
    'mackenzie.seamless.md',
    'mackenzie-test.seamless.md',
    'mahc.seamless.md',
    'mcfadyen.seamless.md',
    'mhalliance.seamless.md',
    'mhi.seamless.md',
    'mississaugacosmetics.seamless.md',
    'montrealortho.seamless.md',
    'mountsinai.seamless.md',
    'myhipandknee.seamless.md',
    'nbrhc.seamless.md',
    'niagara.seamless.md',
    'nshealth.seamless.md',
    'nygh.seamless.md',
    'oakvalleyhealth.seamless.md',
    'ottawa.seamless.md',
    'ottawahospital.seamless.md',
    'qhc.seamless.md',
    'rfp.seamless.md',
    'rodriguez.seamless.md',
    'rvrhc.seamless.md',
    'sault.seamless.md',
    'sehealth.seamless.md',
    'sjhc.seamless.md',
    'sjhh.seamless.md',
    'southlake.seamless.md',
    'sph.seamless.md',
    'stjosephshomecare.seamless.md',
    'stmichaels.seamless.md',
    'sudbury.seamless.md',
    'sunnybrook.seamless.md',
    'tadh.seamless.md',
    'tbrss.seamless.md',
    'tegh.seamless.md',
    'tehn.seamless.md',
    'thp.seamless.md',
    'tmbcosmetics.seamless.md',
    'torontoent.seamless.md',
    'tsh.seamless.md',
    'unity.seamless.md',
    'vch.seamless.md',
    'vitalite.seamless.md',
    'wohs.seamless.md',
  ],

  US: [
    'adventist.seamless.md',
    'atrium.seamless.md',
    'baystate.seamless.md',
    'baystatehealth.seamless.md',
    'carolinas.seamless.md',
    'ccf.seamless.md',
    'cerner.seamless.md',
    'chsli.seamless.md',
    'columbia.seamless.md',
    'crozer.seamless.md',
    'cumc.seamless.md',
    'drboettner.seamless.md',
    'einstein.seamless.md',
    'franciscan.seamless.md',
    'globaloneventures.seamless.md',
    'hackensack.seamless.md',
    'hoag.seamless.md',
    'inova.seamless.md',
    'intuitive.seamless.md',
    'multicare.seamless.md',
    'nuvance.seamless.md',
    'ohiostate.seamless.md',
    'phi.seamless.md',
    'providence.seamless.md',
    'rush.seamless.md',
    'saintpetershcs.seamless.md',
    'sch.seamless.md',
    'scl.seamless.md',
    'sparrow.seamless.md',
    'stanford.seamless.md',
    'tukhs.seamless.md',
    'uab.seamless.md',
    'ucdavis.seamless.md',
    'ucdmc.seamless.md',
    'uh.seamless.md',
    'us-beta.seamless.md',
    'us-beta-1.seamless.md',
    'us-beta-2.seamless.md',
    'us-beta-3.seamless.md',
    'us-demo1.seamless.md',
    'us-demo2.seamless.md',
    'us-demo3.seamless.md',
    'us-demo-cs1.seamless.md',
    'us-demo-cs2.seamless.md',
    'us-demo-sales1.seamless.md',
    'us-demo-sales2.seamless.md',
    'us-support.seamless.md',
    'us-templates.seamless.md',
    'wustl.seamless.md',
  ],
};
