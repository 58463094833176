import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';
import { useAppSelector } from '../../redux/hooks';
import { logout } from '../../patient-app-common/slices/authSlice';
import { useCheckTwoFACodeMutation, useSendTwoFACodeMutation } from '../../patient-app-common/api/authApi';
import { LoaderInline } from '../layout';
import SetTwoFA from '../settings/components/SetTwoFA';


export default function Verify() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('login');

  const { mfaContact, mfaMethod } = useAppSelector(({ auth }) => auth);
  const [sendTwoFACode] = useSendTwoFACodeMutation();
  const [checkTwoFaCode, { isLoading }] = useCheckTwoFACodeMutation();

  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(30);
  const [method, setMethod] = useState(mfaMethod || 'sms');
  const [rememberBrowser, setRememberBrowser] = useState(false);

  useEffect(() => {
    if (_.isEmpty(mfaContact)) return;

    sendTwoFACode({});
  }, []);

  useEffect(() => {
    if (timer === 0) {
      setTimer(null);
    }

    if (!timer) return;

    const intervalId = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  const handleResend = async () => {
    setTimer(30);

    try {
      const result = await sendTwoFACode({
        method,
      }).unwrap();

      if (!result.success) {
        setError(t('verify.error.send_code_failed'));
      }
    } catch (_e) {
      setError(t('verify.error.send_code_failed'));
    }
  };

  const handleSubmit = async () => {
    if (!code) {
      setError(t('verify.error.blank_input'));
    } else {
      try {
        const result = await checkTwoFaCode({ 
          code,
          rememberBrowser,
        }).unwrap();

        if (result.success) {
          navigate('/');
        } else {
          setError(t('verify.error.verification_failed'));
        }
      } catch (_e) {
        setError(t('verify.error.verification_failed'));
      }
    }
  };

  const btnText = useMemo(() => {
    let text = t('common.two_fa.button_resend');
    if (timer) {
      text += ` (${timer}s)`;
    }
    return text;
  }, [timer]);
  
  if (_.isEmpty(mfaContact)) {
    return (
      <SetTwoFA
        dismissible={false}
        fullScreen
        visible
        handleExit={() => navigate('/')}
        mfaType="required"
      />
    )
  }
  
  return (
    <div className="bg-gray-100 h-screen">
      <div className="flex justify-center pt-12">
        <Card>
          <ContentDiv>
            <Title>{t('verify.title')}</Title>
            <Text>
              {t('verify.description', { number: mfaContact })}
            </Text>
            <div className="m-auto flex justify-center w-1/2 pb-12">
              <div className="text-left w-full">
                {error && <p className="error-text pb-4">{error}</p>}
                <Label htmlFor="code">
                  {t('common.two_fa.input_code')}
                  <Input
                    autoComplete="off"
                    error={!_.isEmpty(error)}
                    id="code"
                    name="code"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Label>
                <div className="mt-4">
                  <p>{t('common.two_fa.change_method')}</p>
                  <div className="flex-row item ">

                    <label htmlFor="sms" className="font-bold align-middle">
                      {t('common.two_fa.radio_sms')}
                    </label>
                    <Radio
                      className="ml-2"
                      id="sms"
                      name="method"
                      type="radio"
                      checked={method === 'sms'}
                      onChange={(_e) => setMethod('sms')}
                      value="sms"
                    />
                    <label htmlFor="call" className="font-bold align-middle ml-8">
                      {t('common.two_fa.radio_call')}
                    </label>
                    <Radio
                      className="ml-2"
                      id="call"
                      name="method"
                      type="radio"
                      checked={method === 'call'}
                      onChange={(_e) => setMethod('call')}
                      value="call"
                    />
                    <Btn
                      className="ml-8"
                      disabled={timer}
                      onClick={handleResend}
                    >
                      {btnText}
                    </Btn>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Checkbox
                id="remember"
                type="checkbox"
                checked={rememberBrowser}
                onChange={() => setRememberBrowser(!rememberBrowser)}
              />
              <label htmlFor="remember" className="align-middle">
                {t('verify.checkbox.remember_device')}
              </label>
            </div>
            <button
              className="btn-primary m-4 w-1/3"
              onClick={handleSubmit}
            >
              {t('verify.button.submit')}
            </button>
            <div>
              <button 
                className="text-blue-100"
                onClick={() => dispatch(logout())}
              >
                {t('common.button.return_to_login')}
              </button>
            </div>
            {isLoading && <div><LoaderInline /></div>}
          </ContentDiv>
        </Card>
      </div>
    </div>
  );
}

const Card = styled.div(() => [
  tw`bg-white border border-gray-500 h-auto rounded shadow`,
  tw`w-full lg:w-3/4`,
  css`min-height:32rem`,
]);

const ContentDiv = styled.div(() => [
  tw`p-8 text-center w-full`,
]);

const Title = styled.p(() => [
  tw`text-2xl`,
]);

const Text = styled.p(() => [
  tw`text-lg py-8`,
]);

const Label = styled.label(() => [
  tw`block font-bold`,
]);

const Input = styled.input(({ error }) => [
  tw`form-input border-black block w-full`,
  error && tw`border-red`,
]);

const Checkbox = styled.input(() => [
  tw`form-checkbox text-blue-100 mr-2`,
  tw`border-2 border-black`,
]);

const Radio = styled.input(() => [
  tw`form-radio text-blue-100 mr-2`,
  tw`border-2 border-black`,
]);

const Btn = styled.button(({ disabled }) => [
  tw`bg-blue-10 text-blue-100 text-sm`,
  tw`my-4 py-2 px-4 rounded-full`,
  disabled && tw`text-gray-500 bg-gray-200`,
]);
